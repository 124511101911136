/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const NotoSansJavanese_400Regular = require('./NotoSansJavanese_400Regular.ttf');
export const NotoSansJavanese_500Medium = require('./NotoSansJavanese_500Medium.ttf');
export const NotoSansJavanese_600SemiBold = require('./NotoSansJavanese_600SemiBold.ttf');
export const NotoSansJavanese_700Bold = require('./NotoSansJavanese_700Bold.ttf');
